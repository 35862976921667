import { RequestBuilder, fetchFromMakaira } from '../..'

export default async function fetchAutosuggestResult({
  searchPhrase,
  language,
  count = 8,
  offset = 0,
  customFilter,
}) {
  // There is no need to search for an empty string. Also it results in a 400 - bad request.
  if (!searchPhrase) {
    return {}
  }

  const builder = new RequestBuilder()
  const constraints = builder.getConstraints({ language })

  const isSearch = true

  const body = {
    searchPhrase,
    isSearch,
    enableAggregations: false,
    aggregations: [],
    sorting: [],
    count,
    offset,
    constraints,
    customFilter,
  }

  return await fetchFromMakaira({ body, isSearch })
}
